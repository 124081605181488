import React, { useState } from 'react'
import { Link } from 'gatsby'
import BlockContent from '@sanity/block-content-to-react'
import cx from 'classnames'

import { Serializer } from 'src/utils/serializer'
import { Image } from 'src/components/image'

import {
  LargeArrow
} from 'src/components/svgs'


export interface ProductCarouselProps {
  data: {
    title: string,
    items: any[]
  }
}

export const ProductCarousel = ({ data }: ProductCarouselProps) => {
  const [active, setActive] = useState(0)
  const {
    title,
    items
  } = data

  const Slide = (direction: string) => {
    switch (direction) {
      case 'up':
        if (active !== 0) {
          setActive(active - 1)
        } else {
          setActive(items.length - 1)
        }
      case 'down':
        if (active !== items.length - 1) {
          setActive(active + 1)
        } else {
          setActive(0)
        }
    }
  }
  return (
    <div className='x mt2 mb2 mb4--800 mt4--800 pt3--800 of--hidden pb3--800'>
      <div className='container--xl tc mxa outer p2'>
        <h2 className='s48--1000'>{title}</h2>
      </div>
        <div className='row gutter--none bt bb bc df fw chef__carousel jcb'>
          <div className='col c14 c7--1000 chef__carousel-block'>
            <div className='df y jcb fdc'>
                {items.map((item, i) => (
                  <div key={i} className={cx('p2 chef__carousel-portrait hide--1000 p4--800', {
                    'hide': i !== active
                  })}>
                    <div key={i} className='row gutter--none chef__carousel-images'>
                      <div className='col c14 pr'>
                        {item.chef && item.chef.image && (
                          <Image key={item._id} className='x pa y ' imageId={item.chef.image.imageId} alt={item.chef.image.altText} />
                        )}
                      </div>
                      {/* <div className='col hide--1000 c7 pr'>
                        {item.image && (
                          <Image className={cx('x pa p2--1000 y', {
                            'hide': active !== i
                          })} imageId={item.image.imageId} alt={item.title} />
                        )}
                      </div> */}
                    </div>
                  </div>
                ))}
              <div className='col bt bc hide--1000 tc pr'>
                <div className='row'>
                  <div className='col c1' />
                  <div className='col c5 bl'>
                    <button className='ml1 mr1 p1 button arrow bg--transparent bn' onClick={() => Slide('down')}>
                      <LargeArrow className='flip' />
                    </button>
                  </div>
                  <div className='col bl br c5'>
                    <button className='ml1 mr1 p1 button arrow bg--transparent bn' onClick={() => Slide('up')}>
                      <LargeArrow />
                    </button>
                  </div>
                  <div className='col c1 ' />
                </div>
              </div>
              <div className='chef__carousel-text'>
          
                {items.map((item, i) => (
                  <div className={cx('df jcb fdc y', {
                    'hide': i !== active
                  })} key={i}>
                    <div className='show--1000 chef__carousel--dt p4--800'>
                      {item.image && (
                        <Image className='x  y ' imageId={item.image.imageId} alt={item.title} />
                      )}
                    </div>
                    <div className='p2 pl4--800 pr4--800 bt bc tc x'>

                      {item.customTitle ? (
                        <h3 className='h2 sans' dangerouslySetInnerHTML={{__html: item.customTitle }} />
                        ) : (
                        <h3 className='h2 sans'>{item.title}</h3>)}
                      {item.chef && (<span className='courier s14'>By Chef {item.chef.name}</span>)}
                      {item.description && (<BlockContent blocks={item.description} serializers={Serializer} />)}
                    </div>
                    <div className='row gutter--none bt bc chef__carousel-row'>
                      <div className='col c2' />
                      <div className='col c10'>
                        <Link className="button btn bbn x db" to={`/products/${item.slug}`}><span>Shop the Deck</span></Link>
                      </div>
                      <div className='col c2' />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className='col chef__carousel-center bc show--1000 tc pr'>
            {/* <div className='pa x y top left df jcc aic '>
              <span className='vertical courier'>
              pre-measured spices for 8 recipes
              </span>
            </div> */}
            <div className='df pr z1 y jcc aie'>
              <div className='pb1'>
                <button className='button arrow' onClick={() => Slide('down')}>
                  <LargeArrow className='flip' />
                </button>
                <button className='button arrow' onClick={() => Slide('up')}>
                  <LargeArrow />
                </button>
              </div>
            </div>
          </div>
          <div className='col c14 show--1000 c7--800 chef__carousel-block'>
            <div className='p4 y df jcc aic'>
              <div className='pr chef__carousel-recipe x y'>
                {items.map((item, i) => (
                  <React.Fragment key={i}>
                    {item.image && (
                      <Image key={i} className={cx('x hide--800 y', {
                        'none': active !== i
                      })} imageId={item.image.imageId} alt={item.title} />
                    )}
                    {item.chef && item.chef.image && (
                      <Image key={item._id} className={cx('x pa y', {
                        'none': active !== i
                      })} imageId={item.chef.image.imageId} alt={item.chef.image.altText} />
                    )}
                  </React.Fragment>
                ))}
                
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}

export default ProductCarousel